<template>
  <c-box>
    <c-drawer
      :isOpen="isOpen"
      placement="right"
      :on-close="
        () => {
          $emit('close');
        }
      "
    >
      <c-drawer-overlay />
      <c-drawer-content>
        <c-drawer-header>{{ title }}</c-drawer-header>

        <c-drawer-body
          display="flex"
          overflowY="scroll"
          flexDirection="column"
          pb="8"
        >
          <textarea
            v-if="title === 'Vision' || title == 'Overall Problem'"
            rows="4"
            type="text"
            py="24"
            v-model="context"
            :placeholder="
              title === 'Vision'
                ? 'Enter your business type. eg food delivery startup'
                : 'Enter your business problem here. eg slow delivery of goods.'
            "
          />

          <span v-if="title === 'Belief'"
            >Generate a belief statement based on your vision</span
          >
          <span v-if="title === 'Values'"
            >Generate Key behavioral values to help you reach your goals</span
          >
          <span v-if="title === 'Target Market'"
            >Generate Potential Target markets based on your problem and
            solution.</span
          >
          <span v-if="title === 'Overall Solution'"
            >Generate a solution based on your problem statement</span
          >

          <span v-if="title === 'Overall Value Proposition'"
            >Generate a value proposition based on your problem statement and
            solution provided</span
          >

          <span v-if="title === 'Problem'"
            >Generate problems your target market customers might have</span
          >

          <span v-if="title === 'Solution'"
            >Generate a solution your target market customers might have based
            on their problem</span
          >

          <span v-if="title === 'Value Proposition'"
            >Generate a value proposition for your target market customers based
            on their problem and solution</span
          >

          <span v-if="type === 'channels' || title === 'awareness'"
            >Generate possible channels for your target market customers
          </span>

          <span v-if="title === 'Key Resources'">
            Generate Key Resources needed to sustain and support your business
            based on your value proposition
          </span>

          <span v-if="title === 'Key Activities'">
            Generate Key Activities that your company must perform in order to
            operate successfully
          </span>

          <span v-if="title === 'Key Partners'">
            Generate Key Partners/partnerships that can benefit your company
          </span>

          <span v-if="title === 'Ideal Market Position'">
            Generate an Ideal Market Position to understand where you stand in
            reaching your target customers and market.
          </span>

          <span v-if="title === 'Strategic steps'">
            Generate Strategic steps to take in achieving your target market
            goals and objectives.
          </span>

          <span v-if="type === 'Culture'">
            Generate an Ideal culture for your company
          </span>

          <span v-if="title === 'Point of Differentiation'">
            Generate a brief description on how your company is different from
            your competitors.
          </span>

          <c-button
            @click="handleGetAI()"
            size="md"
            px="8"
            mt="8"
            variant-color="vc-orange"
            variant="outline"
            :isLoading="generateBtnLoading"
          >
            Generate
          </c-button>
          <c-box class="answer" py="4" mt="12" px="2">
            {{ result }}
          </c-box>
          <c-flex gap="10px" v-if="!hideOtherButtons">
            <c-button
              @click="handleAlternate()"
              size="md"
              px="8"
              mt="8"
              variant-color="vc-orange"
              variant="outline"
              :isLoading="alternateBtnLoading"
              :disabled="this.result === ''"
            >
              Alternate
            </c-button>

            <c-button
              @click="handleBullet()"
              size="md"
              px="8"
              mt="8"
              :isLoading="bulletBtnLoading"
              variant-color="vc-orange"
              variant="outline"
              :disabled="this.result === ''"
            >
              Bullet
            </c-button>

            <c-button
              @click="handleShort()"
              size="md"
              px="8"
              mt="8"
              :isLoading="sentenceBtnLoading"
              variant-color="vc-orange"
              variant="outline"
              :disabled="this.result === ''"
            >
              Sentence
            </c-button>
            <c-button
              @click="handleSentence()"
              size="md"
              px="8"
              mt="8"
              :isLoading="shortBtnLoading"
              variant-color="vc-orange"
              variant="outline"
              :disabled="this.result === ''"
            >
              Short Ans
            </c-button>
          </c-flex>
          <c-button
            @click="useResults()"
            size="md"
            mt="8"
            variant-color="vc-orange"
            :disabled="this.result === ''"
          >
            Use This
            <c-text ml="1" fontSize="1.2em" color="#ef5323">👍</c-text>
          </c-button>
        </c-drawer-body>
      </c-drawer-content>
    </c-drawer>
  </c-box>
</template>

<script>
import foundationMixin from '@/mixins/foundation.js';
import businessModelMixin from '@/mixins/business-model.js';
import businessMixin from '@/mixins/business-model.js';
import { getBusinessModelByType } from '@/services/business-model.js';

const errorMessage = {
  title: 'AI Points',
  description: `You don't have enough AI points to query results`,
  status: 'success',
  position: 'top',
  duration: 3000,
};
import { getAI } from '../../../services/openAI';
import { getCustomers } from '@/services/business-model';
import { mapActions, mapState } from 'vuex';
export default {
  mixins: [foundationMixin, businessModelMixin, businessMixin],
  components: {},
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      generateBtnLoading: false,
      bulletBtnLoading: false,
      alternateBtnLoading: false,
      sentenceBtnLoading: false,
      shortBtnLoading: false,
      isDeleteModalOpen: false,
      result: '',
      context: '',
      foundationType: 'vision',
      businessModelType: 'overall_problem',
      businessSection: 'overall_solution',
      businessDescription: '',
      targetMarket: [],
      labelFormText:
        'Put this in label and description form. Label should be name and description should be the description.',
      initialObjectResult: {},
      languageResponse:
        'Generate the answer in the same language as the text provided.',
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
      selectedMarketCustomer: (state) => state.selectedMarketCustomer,
    }),
    hideOtherButtons() {
      return (
        this.title === 'Target Market' ||
        this.title === 'awareness' ||
        this.title === 'evaluation' ||
        this.title === 'purchase' ||
        this.title === 'post_sale_support' ||
        this.title === 'delivery_value' ||
        this.title === 'Key Partners' ||
        this.title === 'Key Activities' ||
        this.title === 'Key Resources' ||
        this.title === 'Values'
      );
    },
  },
  created() {
    this.getCustomers();
  },
  methods: {
    ...mapActions({
      setCustomers: 'company/setCustomers',
    }),
    getCustomers() {
      getCustomers(this.activeCompany.id).then((res) => {
        this.setCustomers(res.data.customer);
        this.targetMarket = res.data.customer[0].name;
      });
    },
    async handleGetAI() {
      this.generateBtnLoading = true;

      if (
        this.title === 'Belief' ||
        this.title === 'Values' ||
        this.title === 'Key Partners'
      ) {
        this.checkVisionStatement();
      }

      if (this.title === 'Vision') {
        try {
          const res = await getAI({
            context: `Generate a vision statement for this vision "${this.context}".`,
          });
          this.generateBtnLoading = false;
          this.result = res.data.data;
        } catch (error) {
          this.generateBtnLoading = false;
          this.$toast({
            title: 'AI Points',
            description: `You don't have enough AI points to query results`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        }
      }

      if (this.title === 'Overall Problem') {
        try {
          const res = await getAI({
            context: `Generate a problem statement for this " ${this.context}". ${this.languageResponse}`,
          });
          this.generateBtnLoading = false;
          this.result = res.data.data;
        } catch (error) {
          this.generateBtnLoading = false;
          this.$toast({
            title: 'AI Points',
            description: `You don't have enough AI points to query results`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        }
      }

      if (this.title === 'Overall Solution') {
        if (this.businessModelData.description === '<ul><li><br></li></ul>') {
          this.generateBtnLoading = false;
          this.$toast({
            title: 'Error',
            description: `Sorry, there has to be an Overall Problem `,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        } else {
          try {
            const res = await getAI({
              context: `Our problem statement is "${this.businessModelData.description}" What could be a potential solution?. ${this.languageResponse}`,
            });
            this.generateBtnLoading = false;
            this.result = res.data.data;
          } catch (error) {
            this.generateBtnLoading = false;
            this.$toast({
              title: 'AI Points',
              description: `You don't have enough AI points to query results`,
              status: 'success',
              position: 'top',
              duration: 3000,
            });
          }
        }
      }

      if (
        this.title === 'Overall Value Proposition' ||
        this.title === 'Target Market'
      ) {
        if (this.businessModelData.description === '<ul><li><br></li></ul>') {
          this.generateBtnLoading = false;
          this.$toast({
            title: 'Error',
            description: `Sorry, there has to be an Overall Problem `,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        } else {
          this.getBusinessModelSection('overall_solution');
        }
      }

      if (this.title === 'Ideal Market Position') {
        if (this.businessModelData.description === '<ul><li><br></li></ul>') {
          this.$toast({
            title: 'Error',
            description: `Sorry, there has to be an Overall Problem, target market and a value preposition `,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        } else {
          // get this.targetMarket in a text form. with coma , separating the values

          try {
            const res = await getAI({
              context: `What is my ideal market position based on this vision: "${this.foundationData.description}" and this target market "${this.targetMarket}".${this.languageResponse}`,
            });
            this.result = res.data.data;
          } catch (error) {
            this.$toast({
              title: 'AI Points',
              description: `You don't have enough AI points to query results`,
              status: 'success',
              position: 'top',
              duration: 3000,
            });
          }
        }
        this.generateBtnLoading = false;
      }

      if (this.title === 'Strategic steps') {
        if (this.businessModelData.description === '<ul><li><br></li></ul>') {
          this.$toast({
            title: 'Error',
            description: `Sorry, there has to be an Overall Problem, target market and a value preposition `,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        } else {
          // get this.targetMarket in a text form. with coma , separating the values

          try {
            const res = await getAI({
              context: `What are some Strategic steps i can take to achieve some market goals if this is my vision: "${this.foundationData.description}" and this target market "${this.targetMarket}". `,
            });
            this.result = res.data.data;
          } catch (error) {
            this.$toast({
              title: 'AI Points',
              description: `You don't have enough AI points to query results`,
              status: 'success',
              position: 'top',
              duration: 3000,
            });
          }
        }
        this.generateBtnLoading = false;
      }

      if (this.title === 'Solution') {
        if (!this.selectedMarketCustomer.problem) {
          this.$toast({
            title: 'Error',
            description: `Sorry, there has to be a Problem for the target market `,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        } else {
          try {
            const res = await getAI({
              context: `Target market problem is "${this.selectedMarketCustomer.problem}" What could be a potential solution?. ${this.languageResponse}`,
            });

            this.result = res.data.data;
            this.generateBtnLoading = false;
          } catch (error) {
            this.generateBtnLoading = false;
            this.$toast({
              title: 'AI Points',
              description: `You don't have enough AI points to query results`,
              status: 'success',
              position: 'top',
              duration: 3000,
            });
          }
        }
      }

      if (this.title === 'Value Proposition') {
        if (!this.selectedMarketCustomer.solution) {
          this.$toast({
            title: 'Error',
            description: `Sorry, there has to be a Solution for the target market `,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        } else {
          try {
            const res = await getAI({
              context: `Target market problem is "${this.selectedMarketCustomer.problem}", and solution is "${this.selectedMarketCustomer.solution}", and target market is "${this.selectedMarketCustomer.name}". Make a value proposition from this. ${this.languageResponse}`,
            });

            this.result = res.data.data;
            this.generateBtnLoading = false;
          } catch (error) {
            this.generateBtnLoading = false;
            this.$toast({
              title: 'AI Points',
              description: `You don't have enough AI points to query results`,
              status: 'success',
              position: 'top',
              duration: 3000,
            });
          }
        }
      }

      if (this.type === 'channels') {
        const expression = this.title;
        let message = '';
        switch (expression) {
          case 'awareness':
            message =
              'Where do you make future customers aware of your product or service?';
            break;
          case 'evaluation':
            message =
              'Where does potential customers gain further knowledge about your product';
            break;
          case 'purchase':
            message = 'Where future customers be purchasing your product?';
            break;
          case 'delivery_value':
            message =
              'Where do you deliver the promised value to your customer?';
            break;
          case 'post_sale_support':
            message = 'Where will you be providing support for your customers?';
            break;
        }

        //  get session storage currentChannel
        const currentChannels = JSON.parse(
          sessionStorage.getItem('currentChannel')
        );

        // loop through currentChannels and get the name of the channel in a text form separated by comma
        let channelNames = '';

        // check if currentChannels is an array or its undefined
        if (Array.isArray(currentChannels)) {
          currentChannels.forEach((channel) => {
            channelNames += `${channel.name}, `;
          });
        } else {
          channelNames = '';
        }

        try {
          const res = await getAI({
            context: `Target market is "${this.selectedMarketCustomer.name}". Apart from these ones ${channelNames}, ${message}. Put this in label and description form. Label should be name of channel and description should be the description. ${this.languageResponse}`,
          });
          // this.initialObjectResult = res.data.data;
          this.result = this.transformTextToObject(res.data.data);
          this.generateBtnLoading = false;
        } catch (error) {
          this.generateBtnLoading = false;
          this.$toast({
            title: 'AI Points',
            description: `You don't have enough AI points to query results`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        }
      }

      if (
        this.title === 'Key Resources' ||
        this.title === 'Problem' ||
        this.title === 'Point of Differentiation'
      ) {
        this.getBusinessModelSection('overall_solution');
      }

      if (this.title === 'Key Activities') {
        if (this.businessModelData.description === '<ul><li><br></li></ul>') {
          this.generateBtnLoading = false;
          this.$toast({
            title: 'Error',
            description: `Sorry, there has to be an Overall Problem `,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        } else {
          try {
            const res = await getAI({
              context: `Our problem statement is "${this.businessModelData.description}". What is an important action my company must perform in order to operate successfully. ${this.labelFormText}. ${this.languageResponse}`,
            });
            // this.initialObjectResult = res.data.data;
            this.result = this.transformTextToObject(res.data.data);
            this.generateBtnLoading = false;
          } catch (error) {
            this.generateBtnLoading = false;
            this.$toast({
              title: 'AI Points',
              description: `You don't have enough AI points to query results`,
              status: 'success',
              position: 'top',
              duration: 3000,
            });
          }
        }
      }

      if (this.title === 'How do you contribute to reach these goals?') {
        const currentGoals = JSON.parse(sessionStorage.getItem('selectedSDG'));

        let goalsList = '';
        currentGoals.forEach((channel) => {
          goalsList += `${channel.name}, `;
        });

        try {
          const res = await getAI({
            context: `How do you contribute to reach these Sustainable Development Goals listed "${goalsList}". ${this.languageResponse}`,
          });
          this.result = res.data.data;
          this.generateBtnLoading = false;
        } catch (error) {
          this.generateBtnLoading = false;
          this.$toast({
            title: 'AI Points',
            description: `You don't have enough AI points to query results`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        }
      }

      if (this.title === 'How will you evaluate your contribution?') {
        const contribution = sessionStorage.getItem('contribution');
        try {
          const res = await getAI({
            context: `How will you evaluate such a contribution? ${contribution} `,
          });
          this.result = res.data.data;
          this.generateBtnLoading = false;
        } catch (error) {
          this.generateBtnLoading = false;
          this.$toast({
            title: 'AI Points',
            description: `You don't have enough AI points to query results`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        }
      }

      if (this.type === 'Culture') {
        const culture = sessionStorage.getItem('culture');

        try {
          const res = await getAI({
            context: `My company culture are these ${culture}. How can i achieve this in practice?. ${this.languageResponse}`,
          });
          this.result = res.data.data;
          this.generateBtnLoading = false;
        } catch (error) {
          this.generateBtnLoading = false;
          this.$toast({
            title: 'AI Points',
            description: `You don't have enough AI points to query results`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        }
      }
    },

    getBusinessModelSection(businessSection) {
      getBusinessModelByType({
        type: businessSection,
        companyId: this.activeCompany.id,
      })
        .then((res) => {
          if (res.data.business_model.length) {
            this.businessSection = res.data.business_model[0].description;
            this.getAIwithSolutionStatement();
          }
        })
        .catch((err) => {
          this.generateBtnLoading = false;
          console.log(err);
        });
    },

    async checkVisionStatement() {
      if (
        this.foundationData.description === '<div><br></div><div><br></div>'
      ) {
        this.$toast({
          title: 'Error',
          description: `Sorry, there has to be a vision statement `,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
        this.generateBtnLoading = false;
      } else {
        this.handleAiStatement();
      }
    },

    async getAIwithSolutionStatement() {
      if (this.title === 'Overall Value Proposition') {
        try {
          const res = await getAI({
            context: `Our problem statement is "${this.businessModelData.description}", and our solution is "${this.businessSection}", our target market is "${this.targetMarket}". Make a value proposition from this.${this.languageResponse}`,
          });

          this.result = res.data.data;
          this.generateBtnLoading = false;
        } catch (error) {
          this.generateBtnLoading = false;
          this.$toast({
            title: 'AI Points',
            description: `You don't have enough AI points to query results`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        }
      }

      if (this.title === 'Target Market') {
        try {
          const res = await getAI({
            context: `Our problem statement is "${this.businessModelData.description}", and our solution is "${this.businessSection}". Define our potential target market customer in a label and description. Label should be name of target market and description should be a description of the target market.`,
          });
          // this.initialObjectResult = res.data.data;
          this.result = this.transformTextToObject(res.data.data);
          this.generateBtnLoading = false;
        } catch (error) {
          this.generateBtnLoading = false;
          this.$toast({
            title: 'AI Points',
            description: `You don't have enough AI points to query results`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        }
      }

      if (this.title === 'Problem') {
        try {
          const res = await getAI({
            context: `What is one problem this target markets ${this.selectedMarketCustomer.name} will have according to this solution statement "${this.businessSection}". ${this.languageResponse}`,
          });

          this.result = res.data.data;
          // save problem to
          this.generateBtnLoading = false;
        } catch (error) {
          this.generateBtnLoading = false;
          this.$toast({
            title: 'AI Points',
            description: `You don't have enough AI points to query results`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        }
      }

      if (this.title === 'Key Resources') {
        try {
          const res = await getAI({
            context: `What are the key resources needed to sustain or support my business using the solution statement "${this.businessSection}" ${this.labelFormText}. ${this.languageResponse}`,
          });
          // this.initialObjectResult = res.data.data;
          this.result = this.transformTextToObject(res.data.data);
          this.generateBtnLoading = false;
        } catch (error) {
          this.generateBtnLoading = false;
          this.$toast({
            title: 'AI Points',
            description: `You don't have enough AI points to query results`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        }
      }

      if (this.title === 'Point of Differentiation') {
        try {
          const res = await getAI({
            context: `Our problem statement is "${this.businessModelData.description}", and our solution is "${this.businessSection}". How do I defer from my competition solving the same or similar problem?`,
          });
          // this.initialObjectResult = res.data.data;
          this.result = res.data.data;
          this.generateBtnLoading = false;
        } catch (error) {
          this.generateBtnLoading = false;
          this.$toast({
            title: 'AI Points',
            description: `You don't have enough AI points to query results`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        }
      }
    },

    transformTextToObject(text) {
      const labelStart = text.indexOf('Label: ') + 'Label: '.length;
      const labelEnd = text.indexOf('\n', labelStart);
      const descriptionStart =
        text.indexOf('Description: ') + 'Description: '.length;

      const label = text.slice(labelStart, labelEnd).trim();
      const description = text.slice(descriptionStart).trim();

      const targetMarket = { label, description };

      return targetMarket;
    },

    async handleAiStatement() {
      if (this.title === 'Belief') {
        try {
          const res = await getAI({
            context: `Get a belief or purpose statement based on this vision statement "${this.foundationData.description}"`,
          });
          this.result = res.data.data;
        } catch (error) {
          this.$toast({
            title: 'AI Points',
            description: `You don't have enough AI points to query results`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        }
      }

      if (this.title === 'Key Partners') {
        try {
          const res = await getAI({
            context: `What type of partnerships can benefit our company. If our vision is ${this.foundationData.description}. ${this.labelFormText}`,
          });
          // this.initialObjectResult = res.data.data;
          this.result = this.transformTextToObject(res.data.data);
          this.generateBtnLoading = false;
        } catch (error) {
          this.generateBtnLoading = false;
          this.$toast({
            title: 'AI Points',
            description: `You don't have enough AI points to query results`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        }
      }

      if (this.title === 'Values') {
        try {
          const res = await getAI({
            context: `My vision is ${this.foundationData.description}. What behavior or behavioral values is key for me to reach my goals. ${this.labelFormText}`,
          });
          this.result = this.transformTextToObject(res.data.data);
          this.generateBtnLoading = false;
        } catch (error) {
          this.generateBtnLoading = false;
          this.$toast(errorMessage);
        }
      }
    },

    async handleAlternate() {
      try {
        const res = await getAI({
          context: `Can i get an alternate of "${this.result}"`,
        });
        this.result = res.data.data;
      } catch (error) {
        console.log(error);
        this.$toast({
          title: 'AI Points',
          description: `You don't have enough AI points to query results`,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      }
      this.alternateBtnLoading = false;
    },

    async handleBullet() {
      this.bulletBtnLoading = true;
      try {
        const res = await getAI({
          context: `Get this "${this.result}" in bullet points`,
        });
        this.result = res.data.data;
      } catch (error) {
        console.log(error);
        this.$toast({
          title: 'AI Points',
          description: `You don't have enough AI points to query results`,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      }
      this.bulletBtnLoading = false;
    },

    async handleShort() {
      this.sentenceBtnLoading = true;
      try {
        const res = await getAI({
          context: `Get this "${this.result}" in one sentence`,
        });
        this.result = res.data.data;
      } catch (error) {
        console.log(error);
        this.$toast({
          title: 'AI Points',
          description: `You don't have enough AI points to query results`,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      }
      this.sentenceBtnLoading = false;
    },
    async handleSentence() {
      this.shortBtnLoading = true;
      try {
        const res = await getAI({
          context: `Get a short version of "${this.result}" `,
        });
        this.result = res.data.data;
      } catch (error) {
        console.log(error);
        this.$toast({
          title: 'AI Points',
          description: `You don't have enough AI points to query results`,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      }
      this.shortBtnLoading = false;
    },

    async useResults() {
      this.$emit('close', this.result);

      // try {
      //   await navigator.clipboard.writeText(String(this.result));
      //   this.$toast({
      //     title: 'Success.',
      //     description: ` Copied successfully.`,
      //     position: 'top',
      //     status: 'success',
      //     duration: 5000,
      //   });
      // } catch (err) {
      //   console.error('Failed to copy text: ', err);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.answer {
  @apply bg-gray-100 h-64;
  overflow-y: scroll;
}

button:hover {
  background-color: #e5724e;
  color: #fff;
}
</style>
